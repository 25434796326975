var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('update-view-header',{attrs:{"headline":"Passende Dienstleistungsangebote für Anfrage 'xxx'","button-path":'/',"button-text":"Zurück zur Anfrage"}}),_c('div',{staticClass:"surface-card p-6 border-1 surface-border"},[_c('DataTable',{ref:"dt",staticClass:"p-datatable-customers",attrs:{"dataKey":"id","value":_vm.filteredOffers,"responsiveLayout":"scroll","filterDisplay":"menu","filters":_vm.filterModel,"sortField":"matching","sortOrder":-1,"showGridlines":""},on:{"update:filters":function($event){_vm.filterModel=$event}}},[_c('Column',{attrs:{"field":"matching","header":"Matching-Score","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(Math.floor(slotProps.data.matching * 100))+"% ")]}}])}),_c('Column',{attrs:{"field":"name","header":"Name"}}),_c('Column',{attrs:{"field":"provider","header":"Unternehmen"}}),_c('Column',{attrs:{"field":"pricePerPerson","header":"Preis pro Person"}}),_c('Column',{attrs:{"field":"details","header":"Übereinstimmung","filterMatchMode":"contains","styles":{'min-width':'200px'},"showFilterMatchModes":false},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return _vm._l((_vm.fields),function(field){return _c('div',{staticClass:"field grid"},[_c('label',{staticClass:"col-fixed text-700 mt-1 w-4"},[_vm._v(" "+_vm._s(field.label)+" ")]),_c('div',{staticClass:"col"},[(field.type === 'matching')?_c('Dropdown',{staticClass:"w-full",attrs:{"option-value":"id","option-label":"label","options":[{id: 2, label: _vm.text[2]}, {id: 1, label: _vm.text[1]}, {id: 0, label: _vm.text[0]}]},model:{value:(_vm.filter[field.key]),callback:function ($$v) {_vm.$set(_vm.filter, field.key, $$v)},expression:"filter[field.key]"}}):_vm._e(),(field.type === 'matchingExact')?_c('Dropdown',{staticClass:"w-full",attrs:{"option-value":"id","option-label":"label","options":[{id: 2, label: _vm.text[2]}, {id: 0, label: _vm.text[0]}]},model:{value:(_vm.filter[field.key]),callback:function ($$v) {_vm.$set(_vm.filter, field.key, $$v)},expression:"filter[field.key]"}}):_vm._e(),(field.type === 'text')?_c('input-text',{staticClass:"w-full",model:{value:(_vm.filter[field.key]),callback:function ($$v) {_vm.$set(_vm.filter, field.key, $$v)},expression:"filter[field.key]"}}):_vm._e()],1)])})}},{key:"body",fn:function(slotProps){return [_c('div',[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"repeat(2, 1fr)","grid-column-gap":"1rem","grid-row-gap":".5rem"}},_vm._l(({
                                    'impactBhiCategory': 'Wirkung auf den BHI',
                                    'typeCategory': 'Kategorien der Dienstleistung',
                                    'targetCategories': 'Wirkungen',
                                    'workingEnvironmentCategories': 'Einfluss',
                                    'orga': 'Organisatorisches',
                                    'period': 'Zeitraum',
                                }),function(label,index){return _c('div',{staticStyle:{"white-space":"nowrap"}},[_c('i',{staticClass:"pi",class:{
                                        'pi-angle-double-up': slotProps.data.matchings[index] === 2,
                                        'pi-angle-up': slotProps.data.matchings[index] === 1,
//                                        'pi-angle-right': slotProps.data.matchings[index] === 0,
                                        'pi-circle-off': slotProps.data.matchings[index] < 1,
                                    }}),_vm._v(" "+_vm._s(label)+" ("+_vm._s(slotProps.data.matchings[index])+") "),_c('br')])}),0)])]}}])}),_c('Column',{attrs:{"header":"","styles":{'text-align': 'right'}},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticClass:"inline-block"},[_c('i',{staticClass:"pi pi-bookmark"})]),_c('a',{staticClass:"inline-block ml-2"},[_c('i',{staticClass:"pi pi-search"})])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }