<template>
    <div>
        <update-view-header
            headline="Passende Dienstleistungsangebote für Anfrage 'xxx'"
            :button-path="'/'"
            button-text="Zurück zur Anfrage"
        />

        <div class="surface-card p-6 border-1 surface-border">
            <DataTable
                dataKey="id"
                ref="dt"
                :value="filteredOffers"
                responsiveLayout="scroll"
                filterDisplay="menu"
                class="p-datatable-customers"
                :filters.sync="filterModel"
                sortField="matching"
                :sortOrder="-1"
                showGridlines
            >
                <Column field="matching" header="Matching-Score" sortable>
                    <template #body="slotProps">
                        {{ Math.floor(slotProps.data.matching * 100) }}%
                    </template>
                </Column>
                <Column field="name" header="Name"></Column>
                <Column field="provider" header="Unternehmen"></Column>
                <Column field="pricePerPerson" header="Preis pro Person"></Column>

                <Column
                    field="details"
                    header="Übereinstimmung"
                    filterMatchMode="contains"
                    :styles="{'min-width':'200px'}"
                    :showFilterMatchModes="false"
                >
                    <template #filter="{filterModel}">
                        <div class="field grid" v-for="field in fields">
                            <label class="col-fixed text-700 mt-1 w-4">
                                {{ field.label }}
                            </label>
                            <div class="col">
                                <Dropdown
                                    v-if="field.type === 'matching'"
                                    class="w-full"
                                    v-model="filter[field.key]"
                                    option-value="id"
                                    option-label="label"
                                    :options="[{id: 2, label: text[2]}, {id: 1, label: text[1]}, {id: 0, label: text[0]}]"
                                />
                                <Dropdown
                                    v-if="field.type === 'matchingExact'"
                                    class="w-full"
                                    v-model="filter[field.key]"
                                    option-value="id"
                                    option-label="label"
                                    :options="[{id: 2, label: text[2]}, {id: 0, label: text[0]}]"
                                />
                                <input-text
                                    class="w-full"
                                    v-if="field.type === 'text'"
                                    v-model="filter[field.key]"
                                />
                            </div>
                        </div>
                    </template>
                    <template #body="slotProps">
                        <div>
                            <div
                                style="display: grid; grid-template-columns: repeat(2, 1fr); grid-column-gap: 1rem; grid-row-gap: .5rem;">
                                <div v-for="label, index in {
                                    'impactBhiCategory': 'Wirkung auf den BHI',
                                    'typeCategory': 'Kategorien der Dienstleistung',
                                    'targetCategories': 'Wirkungen',
                                    'workingEnvironmentCategories': 'Einfluss',
                                    'orga': 'Organisatorisches',
                                    'period': 'Zeitraum',
                                }"
                                     style="white-space: nowrap;"
                                >
                                    <i
                                        class="pi"
                                        :class="{
                                        'pi-angle-double-up': slotProps.data.matchings[index] === 2,
                                        'pi-angle-up': slotProps.data.matchings[index] === 1,
//                                        'pi-angle-right': slotProps.data.matchings[index] === 0,
                                        'pi-circle-off': slotProps.data.matchings[index] < 1,
                                    }">

                                    </i>
                                    {{ label }} ({{ slotProps.data.matchings[index] }})
                                    <br>
                                </div>
                            </div>
                        </div>
                    </template>
                </Column>

                <Column header="" :styles="{'text-align': 'right'}">
                    <template #body="slotProps">
                        <div style="white-space: nowrap;">
                            <a class="inline-block">
                                <i class="pi pi-bookmark"></i>
                            </a>
                            <a class="inline-block ml-2">
                                <i class="pi pi-search"></i>
                            </a>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";
    import InputText from "primevue/inputtext/InputText";
    import Dropdown from "primevue/dropdown/Dropdown";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";

    export default {
        components: {UpdateViewHeader, InputText, Dropdown, DataTable, Column},
        computed: {
            filteredOffers: function () {
                let offers = this.offers.concat([]);
                let deleteOffers = [];

                offers.forEach((offer) => {
                    Object.keys(this.filter).forEach((item) => {
                        if (offer.matchings.hasOwnProperty(item) && Number.isInteger(offer.matchings[item])) {
                            if (offer.matchings[item] < this.filter[item]) {
                                deleteOffers.push(offer.id);
                            }
                        }
                    });
                });

                let newOffers = [];
                offers.forEach((offer) => {
                    if(!deleteOffers.includes(offer.id)) {
                        newOffers.push(offer);
                    }
                });

                return newOffers;
            },
            offers: function () {
                let offers = [];
                for (let i = 0; i < 30; i++) {
                    let offer = {
                        id: (i + 1),
                        matching: 0,
                        name: (Math.random() > 0.5 ? 'Günstiges Angebot ' : 'Angebot ') + (i + 1),
                        provider: 'Unternehmen ' + (i + 1),
                        pricePerPerson: Math.floor(Math.random() * 100) + 50,
                        matchings: {
                            impactBhiCategory: Math.floor(Math.random() * 3),
                            typeCategory: Math.floor(Math.random() * 3),
                            targetCategories: Math.floor(Math.random() * 3),
                            workingEnvironmentCategories: Math.floor(Math.random() * 3),
                            period: Math.floor(Math.random() * 3),
                            orga: Math.floor(Math.random() * 3),
                        }
                    };

                    if (offer.matchings.impactBhiCategory === 1) {
                        offer.matchings.impactBhiCategory = 2;
                    }
                    if (offer.matchings.typeCategory === 1) {
                        offer.matchings.typeCategory = 2;
                    }

                    offer.matching += offer.matchings.impactBhiCategory * 4;
                    offer.matching += offer.matchings.typeCategory * 4;
                    offer.matching += offer.matchings.targetCategories * 2;
                    offer.matching += offer.matchings.workingEnvironmentCategories * 2;
                    offer.matching += offer.matchings.period * 1;
                    offer.matching += offer.matchings.orga * 1;

                    offer.matching = offer.matching / (8 + 8 + 4 + 4 + 2 + 2);

                    offers.push(offer);
                }

                return offers;
            }
        },
        mounted() {
            this.filter = Object.assign({}, this.baseFilter);
        },
        data() {
            return {
                baseFilter: {
                    request: 'Anfrage von Unternehmen A',
                    impactBhiCategory: 0,
                    typeCategory: 0,
                    targetCategories: 0,
                    workingEnvironmentCategories: 0,
                    orga: 0,
                    period: 0,
                },
                text: [
                    'alle anzeigen',
                    'weitestgehend passend anzeigen',
                    'nur genau passende anzeigen',
                ],
                filter: {},
                filterModel: {
                    details: {},
                },
                fields: [
                    {
                        label: 'Wirkung auf den BHI',
                        key: 'impactBhiCategory',
                        type: 'matchingExact',
                    },
                    {
                        label: 'Kategorien der Dienstleistungen, Art der Intervention',
                        key: 'typeCategory',
                        type: 'matchingExact',
                    },
                    {
                        label: 'Wirkungen',
                        key: 'targetCategories',
                        type: 'matching',
                    },
                    {
                        label: 'Einfluss',
                        key: 'workingEnvironmentCategories',
                        type: 'matching',
                    },
                    {
                        label: 'Organisatorische Rahmenbedingungen',
                        key: 'orga',
                        type: 'matching',
                    },
                    {
                        label: 'Zeitraum',
                        key: 'period',
                        type: 'matching',
                    }
                ]
            }
        }
    }
</script>
